import React from 'react'

const Footer = () => (
  <div className="container">
    <footer className="pt-4 my-md-5 pt-md-5 border-top">
    <div className="row">
      <div className="col-12 col-md">
        <small className="d-block mb-3 text-muted">Justin Pinkney &copy; 2020</small>
      </div>
      <div className="col-6 col-md">
        {/* <h5>Technology</h5>
        <ul className="list-unstyled text-small">
          <li><a className="text-muted" href="#">How does this work?</a></li>
          <li><a className="text-muted" href="#">Examples</a></li>
          <li><a className="text-muted" href="#">Our models</a></li>
          <li><a className="text-muted" href="#">API</a></li>
        </ul> */}
      </div>
      <div className="col-6 col-md">
        {/* <h5>About</h5>
        <ul className="list-unstyled text-small">
          <li><a className="text-muted" href="#">About</a></li>
          <li><a className="text-muted" href="#">FAQ</a></li>
          <li><a className="text-muted" href="#">Privacy</a></li>
          <li><a className="text-muted" href="#">Contact</a></li>
        </ul> */}
      </div>
    </div>
  </footer>
  </div>
)

export default Footer